@font-face {
  font-family: 'MSMedium';
  src: url('./../Assets/Fonts/Mona-Sans-Medium.otf');
}

@font-face {
  font-family: 'MSMediumWide';
  src: url('./../Assets/Fonts/Mona-Sans-MediumWide.otf');
}

@font-face {
  font-family: 'MSMediumRegular';
  src: url('./../Assets/Fonts/Mona-Sans-Regular.otf');
}

@font-face {
  font-family: 'MSMediumItalic';
  src: url('./../Assets/Fonts/Mona-Sans-MediumItalic.otf');
}

@font-face {
  font-family: 'MSXenonMediumItalic';
  src: url('./../Assets/Fonts/MonaspaceXenon-MediumItalic.otf');
}
