.project a:hover svg {
  @apply text-fg/100-light dark:text-fg/100-dark;
}

.project:hover {
  @apply scale-110 opacity-100 !important;
}

.project:nth-child(2n) {
  @apply xl:translate-y-6;
}
